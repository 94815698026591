import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';

import TooltipButton from '../../utils/ui/TooltipComponent.jsx';
import doneIcon from '../../assets/icons/done.svg';

const BorderLinearProgress = styled(LinearProgress)(({ theme, content }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    // ...theme.applyStyles('dark', {
    //   backgroundColor: theme.palette.grey[800],
    // }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: content,
    // ...theme.applyStyles('dark', {
    // backgroundColor: 'blue',
    // }),
  },
}));

export default function ProductRaffleProgressBars({ amount, totalAmount, salesTargetOfTickets }) {
  const { t } = useTranslation();

  const soldTicket = totalAmount - amount;
  const percent = Math.floor(((totalAmount - amount) / totalAmount) * 100);
  const circlePosition = Math.floor((salesTargetOfTickets / totalAmount) * 100) - 2;

  const color = percent <= 50 ? '#7CD486' : percent <= 80 ? '#FF9C56' : '#F95353';
  const circleColor = percent < circlePosition ? '#EEEEEE' : color;

  const fontSize = 14,
    fontWeight = 300;

  let marginLeftForSalesTargetOfTickets =
    !salesTargetOfTickets || salesTargetOfTickets.toString().length < 3
      ? 0
      : `-${(salesTargetOfTickets.toString().length - 2) * 5 + 1}px`;

  return (
    <Box
      spacing={0}
      direction={'column'}
      sx={{
        flexGrow: 1,
        marginLeft: 'clamp(5px, 1%, 10px)',
        marginTop: 4,
        minWidth: 320,
        maxWidth: 350,
        position: 'relative',
      }}
    >
      <BorderLinearProgress variant="determinate" value={percent} content={color} />
      <Typography
        component="div"
        fontSize={fontSize}
        fontWeight={fontWeight}
        className="flex"
        sx={{ justifyContent: 'space-between' }}
      >
        <span>0</span>
        <span>{totalAmount}</span>
      </Typography>

      {!!salesTargetOfTickets && (
        <Box sx={{ position: 'absolute', left: `${circlePosition}%`, top: -3.5 }}>
          <Box
            component="div"
            sx={{ bgcolor: circleColor, width: 16, height: 16, borderRadius: '50%', position: 'relative' }}
          >
            <Typography
              component="div"
              className="flex"
              fontSize={fontSize}
              fontWeight={fontWeight}
              sx={{ position: 'absolute', top: -20, marginLeft: '-70%' }}
            >
              Target
              <TooltipButton
                marginTop={-3}
                marginLeft={2}
                marginBottom={0}
                width={15}
                title={t('The minimum amount of ticket sales that the seller would like to reach.')}
              />
            </Typography>

            <Typography
              className="flex"
              fontSize={fontSize}
              fontWeight={fontWeight}
              sx={{ position: 'absolute', top: 20, marginLeft: marginLeftForSalesTargetOfTickets }}
            >
              {salesTargetOfTickets}
              {soldTicket >= salesTargetOfTickets && <img src={doneIcon} alt="Done" style={{ width: 18 }} />}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}
